/* responsible for the styling for the AllProjects components */

/* Creates a flex container for all projects*/
.all-projects-container {
	display: flex;
	flex-wrap: wrap;
	align-items: stretch;
	padding-top: 30px;
	padding-bottom: 30px;
	margin-left: -35px;
	margin-right: -35px;
}
/* Sets each project to take up to 1/3 of the width */
.all-projects-project {
	width: calc(100% / 3);
	box-sizing: border-box;
	padding: 10px;
	overflow: hidden;
}
/* A media query for smaller screens that changes the layout to 2 columns */
@media (max-width: 600px) {
	.all-projects-project {
		width: calc(100% / 2);
	}
}
